<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">報告管理 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchMiddle inlineBlock searchSpace">
            <div>報告者名</div>
            <div>
              <b-form-input
                @input="setFilter('name', $event)"
                :value="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                @input="setFilter('year', $event)"
                :value="search.year"
                :options="yearList">
                <template v-slot:first>
                  <option :value="'all'">すべて</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成種別</div>
            <div>
              <b-form-select
                @input="setFilter('series', $event)"
                :value="search.series"
                :options="seriesList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請番号</div>
            <div>
              <b-form-input
                :value="search.code"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock">
            <div>ステータス</div>
            <div>
              <b-form-select
                @input="setFilter('status', $event)"
                :value="search.status"
                :options="statusList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsAppTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="repList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          @sort-changed="onSortChanged"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1">全選択/全解除</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 link" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 link" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 link" @click="allSelect">全選択</p>
                <p class="mb-0 link" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedRepIdList($event, row.item.id)"
                :checked="selectedRepIdList"
                :value="row.item.id"
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="'/cms/reports/edit/'+row.item.id">
              編集
            </b-link>
          </template>
        </b-table>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="selectedRepIdList.length < 1"
        @click="control(status['RECEIPT'])"
        >一括受領</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="!canExportPdf"
        @click="exportPdf()"
        >PDF出力</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
// eslint-disable-next-line
import download from '@/modules/download';

export default {
  name: 'CmsApplicationsList',
  data() {
    return {
      status: CONST_STATUS.APP_STATUS,
      statusList: CONST_STATUS.CMS_REP_STATUS_OPTION_4_LIST,
      dbRepList: [],
      header: [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'kana', label: '報告者名', sortable: true },
        { key: 'year', label: '年度', sortable: true },
        { key: 'reptype', label: '助成種別', sortable: true },
        { key: 'code', label: '報告番号', sortable: true },
        { key: 'statusText', label: 'ステータス', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ],
      pageOptions: [
        { value: 1, text: 1 },
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      rawSeriesList: [],
      yearList: [],
      isInit: true,
      oldPage: 1,
      sortBy: null,
      sortDesc: false,
      tableShowData: [],
    };
  },
  methods: {
    onSortChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.setSortedData();
    },
    setSortedData() {
      if (this.sortBy) {
        const sortedData = this.tableShowData.slice().sort((a, b) => {
          let result = 0;
          if (a[this.sortBy] < b[this.sortBy]) {
            result = -1;
          } else if (a[this.sortBy] > b[this.sortBy]) {
            result = 1;
          }
          return this.sortDesc ? -result : result;
        });
        this.$store.commit('cmsReportSearch/setFilterdIdList', sortedData);
      }
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsReportSearch/setFilter', param);
    },
    async initFetch() {
      const promiseFuncs = [api.send('/api/cms/reports/init')];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      this.rawSeriesList = responses[0].data.seriesList;
      this.yearList = responses[0].data.yearList;
      // 年度が選択されていなければ、最新の年度を選択状態にする
      if (!this.search.year && this.yearList.length > 0) {
        const yearParam = { key: 'year', value: this.yearList[0] };
        this.$store.commit('cmsApplicationSearch/setFilter', yearParam);
      }
      await this.fetchRepList();
    },
    // 報告一覧取得
    async fetchRepList() {
      const param = {
        year: this.search.year,
        series: this.search.series,
      };
      const response = await api.send('/api/cms/reports/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.dbRepList = response.data.repList;
    },
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const reptypeSort = search.series === null || lineData.seriesId === search.series;
      // const yearSort = search.year === null || lineData.year === search.year;
      const codeSort = search.code === '' || lineData.code.includes(search.code);
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      return nameSort && reptypeSort && codeSort && statusSort;
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsReportSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsReportSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsReportSearch/setCurrentPage', 1);
      }
      // 編集ページ内での次の報告、前の報告に移動するためのIDリストの保存
      this.$store.commit('cmsReportSearch/initSelectedAppid');
      this.$store.commit('cmsReportSearch/setFilterdIdList', filteredItems);
      this.tableShowData = filteredItems;
      this.setSortedData();
    },
    async control(status) {
      const count = this.selectedRepIdList.length;
      let msg = `選択中の${count}件の報告のステータスを受領に変更します。`;
      msg += '\nステータスが変更されると、報告者にメールの送信と通知の登録が行われます。\n受領してもよろしいですか？';
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        status,
        selectedRepIdList: this.selectedRepIdList,
      };
      const response = await api.send('/api/cms/reports/control', params)
        .catch((err) => {
          console.log(err);
        });
      if (!response) {
        return;
      }
      await this.alert('選択した報告を受領しました。', false);
      await this.initFetch(this.search.year);
      this.$store.dispatch('page/offWaiting');
    },
    allSelect() {
      this.$store.commit('cmsReportSearch/allSelectedAppid');
    },
    allRemove() {
      this.$store.commit('cmsReportSearch/initSelectedAppid');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsReportSearch/addSelectedAppid', appId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsReportSearch/removeSelectedAppid', appId);
      });
    },
    async exportPdf() {
      if (!this.canExportPdf) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedRepIdList: this.selectedRepIdList,
        seriesId: this.search.series,
        year: this.search.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/reports/export/pdf', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        // eslint-disable-next-line
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const series = this.seriesList.find((data) => {
        return data.value === this.search.series;
      });
      const fileName = `${ymd}_${series.text}.pdf`;
      download.blob(response.data, fileName);
    },
    updateSelectedRepIdList(event, appId) {
      if (!event) {
        this.$store.commit('cmsReportSearch/removeSelectedAppid', appId);
      } else {
        this.$store.commit('cmsReportSearch/addSelectedAppid', appId);
      }
    },
    setCurrentPage(page) {
      this.$store.commit('cmsReportSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsReportSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsReportSearch/setTotalRows', value);
    },
  },
  computed: {
    search() {
      return this.$store.state.cmsReportSearch.search;
    },
    currentPage() {
      return this.$store.state.cmsReportSearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsReportSearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsReportSearch.totalRows;
    },
    repList() {
      if (!this.dbRepList) {
        return [];
      }
      const repList = this.dbRepList.map((report) => {
        return {
          id: report.id,
          reptypeId: report.report_type_id,
          reptype: report.apptype_name,
          name: `${report.user_info.sei} ${report.user_info.mei}`,
          kana: `${report.user_info.kana_sei} ${report.user_info.kana_mei}`,
          year: report.year,
          code: report.code,
          status: report.status,
          statusText: CONST_STATUS.APP_STATUS_TEXTS[report.status],
          seriesId: report.series_id,
        };
      });
      return repList;
    },
    seriesList() {
      if (!this.rawSeriesList) {
        return [];
      }
      return this.rawSeriesList.map((series) => {
        return { value: series.id, text: series.name };
      });
    },
    selectedRepIdList() {
      if (!this.$store.state.cmsReportSearch.selectedRepIdList) {
        return [];
      }
      return this.$store.state.cmsReportSearch.selectedRepIdList;
    },
    filterdIdList() {
      return this.$store.state.cmsReportSearch.filterdIdList;
    },
    canExportPdf() {
      const { year, series } = this.search;
      const number = this.selectedRepIdList.length > 0;
      return number && series && year !== null;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  watch: {
    'search.year': async function () {
      this.$store.dispatch('page/onWaiting');
      await this.initFetch();
      this.$store.dispatch('page/offWaiting');
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.oldPage = this.currentPage;
    this.$store.commit('cmsReportSearch/initSelectedAppid');
    const { year, seriesId } = this.$route.params;
    if (year) {
      this.setFilter('year', Number(year));
    }
    if (seriesId) {
      this.setFilter('series', Number(seriesId));
    }
    if (!this.search.year) {
      this.setFilter('year', Number(moment().format('YYYY')));
    }
    await this.initFetch();
    this.isInit = false;
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .cmsAppTable thead th:nth-of-type(1) {
    width: 140px !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
